import React, { useEffect, useRef, useState } from "react";
import { isFunction, isSet, isString } from "../../../../_metronic/utils/utils";
import "./PasswordRequestForm.css";
import Constants from "../../../store/config/Constants";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl"; //Multi-idioma
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
 

import { toast } from "../.././../store/ducks/notify-messages";

const useStyles = makeStyles(theme => ({
    hiberBlanco: {
        color: "white",
    },
    hiberAmarillo: {
        color: "#ffbf00",
    },
    hiberIzquierda: {
        marginRight: "5px"
    },

}));


const useCustomForm = (callback) => {
    const [inputs, setInputs] = useState({});

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        callback({ inputs, event });
    }
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
    }
    return {
        handleSubmit,
        handleInputChange,
        inputs
    };
}

const PasswordRequestForm = ({
    isOpen = false,
    username = "",
    passwordPlaceholder = 'Contraseña',
    avatarImage = undefined,
    linkMessage = 'AUTH.LABEL.HIBERNACION.START.SESSION',
    errorMessage: usrErrorMessage = "",
    timeoutErrorMessage,
    onSendCredential: usrOnSendCredential,
    onBackLogin: usrOnBackLogin,
    onCleanedErrorMessage,
    intl,
}) => {

    const timeoutRef = useRef();
    const [firstLetterUsername] = useState(isString(username) && username.length > 0 ? username[0].toUpperCase() : undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const clsHiber = useStyles();

    const onSendCredential = () => {
        const { password } = inputs;
        //OBTENER - reCAPTCHA de google.
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(Constants.CAPTCHA_SECRETKEYSITE, { action: 'submit' }).then(token => {
                //console.log("get token password request form: ", token);
                if (isFunction(usrOnSendCredential)) usrOnSendCredential({ password, token });

            });
        });
    }

    const { inputs, handleInputChange, handleSubmit } = useCustomForm(onSendCredential);
    const onBackLogin = () => isFunction(usrOnBackLogin) ? usrOnBackLogin() : () => { };

    const onSubmit = event => handleSubmit(event);
    const showErrorMessage = () => {
        if (isString(usrErrorMessage) && isSet(usrErrorMessage)) {
            setErrorMessage(usrErrorMessage);
            if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
                setErrorMessage(undefined);
                if (isFunction(onCleanedErrorMessage)) {
                    onCleanedErrorMessage();
                }

                clearTimeout(timeoutRef.current);
            }, timeoutErrorMessage);
        }
    }



    useEffect(() => {
        return () => {
            if (!!timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = false;
        };
    }, []);

    useEffect(
        () => {
            showErrorMessage();
        }
        , [usrErrorMessage, timeoutErrorMessage]);



        

    return (
        <>
            {
              !!isOpen && !!username &&
                <div className="backdrop">
                    <div className="page page-lock ng-scope">
                        <div className="lock-centered clearfix">
                            <div className="lock-container">

                                <section className="clsHibernacionLogin">
                                    {
                                        (errorMessage !== "") ? (
                                            toast("info", errorMessage)
                                        ) : (
                                            toast("info", intl.formatMessage({ id: "AUTH.LABEL.HIBERNACION.MESSAGER" }))
                                        )
                                    }
                                    <div className="">
                                        <a href="#" onClick={() => {
                                            onBackLogin();
                                            // hideToast();
                                        }} className={clsHiber.hiberAmarillo}>
                                            <ExitToAppIcon /> {intl.formatMessage({ id: linkMessage })}
                                        </a>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    );
};
export default injectIntl(PasswordRequestForm);