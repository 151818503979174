export default {
    header: {
        self: {},
        items: [
            {
                title: "Inicio",
                root: true,
                alignment: "left",
                page: "dashboard",
                translate: "MENU.DASHBOARD"
            }
        ]
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "flaticon2-architecture-and-city",
                page: "dashboard",
                translate: "MENU.DASHBOARD",
                bullet: "dot"
            },
            { section: "Custom" },
        ]
    }
};
