import React from "react";
const EyeShowIcon = ({ width = "20px", height = "20px", stroke = "black" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
      <path d="M8.94199 4.22685C9.29329 4.1861 9.64667 4.16579 10.0003 4.16602C13.887 4.16602 17.0003 6.58518 18.3337 9.99935C18.0109 10.8298 17.5748 11.6117 17.0378 12.3227M5.43366 5.43185C3.73366 6.46935 2.41699 8.07685 1.66699 9.99935C3.00033 13.4135 6.11366 15.8327 10.0003 15.8327C11.6104 15.8412 13.1913 15.4027 14.567 14.566M8.23366 8.23268C8.00149 8.46485 7.81733 8.74047 7.69168 9.04381C7.56603 9.34715 7.50136 9.67227 7.50136 10.0006C7.50136 10.3289 7.56603 10.6541 7.69168 10.9574C7.81733 11.2607 8.00149 11.5364 8.23366 11.7685C8.46582 12.0007 8.74145 12.1848 9.04479 12.3105C9.34813 12.4361 9.67324 12.5008 10.0016 12.5008C10.3299 12.5008 10.655 12.4361 10.9584 12.3105C11.2617 12.1848 11.5373 12.0007 11.7695 11.7685" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.33398 3.33301L16.6673 16.6663" stroke={stroke} stroke-width="2" stroke-linecap="round"/>
    </svg>
  );
};

export default EyeShowIcon;
