import React from "react";
import { injectIntl } from "react-intl";
//import { useIntl } from "react-intl";
class HMenuItemInner extends React.Component {



  itemCssClassWithBullet(item, parentItem) {
    if (item.bullet) {
      return `kt-menu__link-bullet--${item.bullet}`;
    }

    if (parentItem && parentItem.bullet) {
      return `kt-menu__link-bullet--${parentItem.bullet}`;
    }

    return "";
  }


  getAltasBajasMenu(item) {
    const capitalized = item.charAt(0).toUpperCase() + item.slice(1)
    return capitalized;
  }

  render() {
    const { item, parentItem, intl } = this.props;
    //  const { intl, setLoading } = props; 
    return (
      <>
        {/* if menu item has icon */}
        {item.icon && (
          <i className={`kt-menu__link-icon ${item.icon}`} />
        )}

        {/* if menu item using bullet */}
        {(!item.icon && (item.bullet || (parentItem && parentItem.bullet))) && (
          <i className={`kt-menu__link-bullet ${this.itemCssClassWithBullet(item, parentItem)}`}><span /></i>
        )}

        {!item.badge ? (
          <>
            <span className="kt-menu__item-here" />
            {/* menu item title text */}

            {(item.hasOwnProperty("Tag")) ?
              <span className="kt-menu__link-text clsMenuCapitalizable idRef8451">{this.getAltasBajasMenu((intl.formatMessage({ id: item.Tag })).toLowerCase()) }</span>
              :
              <span className="kt-menu__link-text  idRef8442">{item.title}</span>
            }

          </>
        ) : (
          <>
            {/* menu item with badge */}
            <span className="kt-menu__link-text  idRef84121">{item.title}</span>
            <span className="kt-menu__link-badge">
              <span className={`kt-badge kt-badge--brand kt-badge--inline kt-badge--pill ${item.badge.type} `}>
                {item.badge.value}
              </span>
            </span>
          </>
        )}
      </>
    );
  }
}

export default injectIntl(HMenuItemInner);
