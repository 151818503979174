/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
// import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import * as perfilStore from "../../store/ducks/perfil.duck";
import { removeAllLocalStorageCustomDatagrid } from '../../../_metronic/utils/securityUtils';
import { injectIntl } from "react-intl";
import { COLORS } from "../../values/colors"

class UserProfile extends React.Component {

  render() {

    //Se obtiene valores del perfil anterior:
    const { intl } = this.props;
    let { Contratista, ResponsableContratista, IdCompania } = this.props.perfil;
    const { user, showHi, showBadge, perfil, perfiles, setPerfilActual, datosAutorizador } = this.props;

    const Compania = perfil.Compania || '';

    const establecerPerfil = (IdCliente, IdDivision, Cliente, Division, IdPerfil, Perfil, Contratista, ResponsableContratista, IdCompania, RolAcreditacion) => {
      const perfilSeleccionado = {
        IdCliente,
        IdDivision,
        Cliente,
        Division,
        IdPerfil,
        Perfil,
        Contratista, ResponsableContratista, IdCompania,
        RolAcreditacion
      };
      setPerfilActual(perfilSeleccionado);
    };
    //funcion ReaLoad
    const reLoadPage = () => {
      setTimeout(() => window.location.reload(), 400);
    }

    function truncate(str, limit) {
      return (str.length < limit) ? str : str.substring(0, limit).replace(/\w{3}$/gi, '...');
    }



    const isShortLong = (str) => {

      let isString = ""
      if (str.length > 14) {
        isString = str.substring(0, 14) + '...'
      } else {
        isString = str
      }

      return isString;
    }
    //LISTA DE SEDES
    var menuPerfiles = perfiles.map(perfilItem => {
      const { IdCliente, IdDivision, Cliente, Division, IdPerfil, Perfil } = perfilItem;
      let handleClick = () => {
        //ENVIA UNA NUEVA SOLICITUD AL REDUCER
        establecerPerfil(IdCliente, IdDivision, Cliente, Division, IdPerfil, Perfil, '', '', '', perfil.RolAcreditacion);
        removeAllLocalStorageCustomDatagrid();
        setTimeout(() => window.location.reload(), 400);
      };

      return (
        <>
          <a
            className="kt-notification__item"
            key={`${perfilItem.IdDivision}-${perfilItem.IdCliente}`}
            href="#"
            value={`${perfilItem.IdDivision}-${perfilItem.IdCliente}`}
            onClick={handleClick}
          >
            <div className="kt-notification__item-icon">
              {perfilItem.IdDivision == perfil.IdDivision && perfilItem.IdCliente == perfil.IdCliente ?
                <img src="/media/imgv1/userprofile_iconCurrent.svg" style={{ height: "16px", width: "16px" }} /> :
                <img src="/media/imgv1/userprofile_icon.svg" style={{ height: "16px", width: "16px" }} />
              }
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title" style={{ fontSize: "10px", fontWeight: "700", letterSpacing: "0.25px", lineHeight: "18px" }}>{`${perfilItem.Division}`}</div>
            </div>
          </a>
        </>
      );
    });

    return (
      <Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
          {showHi && <span className="kt-header__topbar-welcome kt-hidden-mobile"></span>}

          {showHi && (
            <div>
              <div className="text-right">
                <span className="kt-header__topbar">
                  <div style={{
                    display: 'flex',
                    fontSize: '12px',
                    color: 'white',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    padding: '0 0.55rem 0 0'
                  }}>
                    {<>{truncate(`${user.username}`, 51)} <br /></>}
                    {/* {perfil.RolAcreditacion} */}

                  </div>
                </span>
              </div>
            </div>
          )}
          {/* src\_metronic\_assets\sass\global\components\typography\_badge.scss */}
          {showBadge && (
            <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold profileRound idRef821411"
              style={{ background: COLORS.secure }}>
              {`${user.firstName}`.slice(0, 1)}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-md" >
          <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
            <div className="kt-user-card__name">
              <h1 style={{ fontSize: '20px', fontWeight: '700', lineHeight: '28px', letterSpacing: '0.75px' }}>
                {isShortLong(`${user.firstName} ${user.lastName}`)}
              </h1>
              <h6 style={{ color: `white`, fontSize: '12px', fontWeight: '400', letterSpacing: '0.75px', lineHeight: '20px', marginBottom: "0px" }}>
                {`${perfil.Perfil}`}
              </h6>

              {/* <p style={{ color: `#ffbf00`, fontSize: '10px', fontWeight: '400', letterSpacing: '0.75px', lineHeight: '20px', marginBottom: "0px" }}>
                {!datosAutorizador.esAutorizador || perfil.RolAcreditacion === "" ? null : (<>ROL : {perfil.RolAcreditacion}</>)}
              </p> */}

              <div className="kt-notification__item-details" style={{
                position: 'relative',
                top: '13px',
                color: 'white',
                fontSize: '10px',
                letterSpacing: '0.25px',
                fontWeight: '400'
              }}>
                <div className="kt-notification__item-title"> {truncate(Compania, 38)}</div>
              </div>
            </div>
          </div>

          <div className="kt-notification">
            <div className="kt-notification__item-details" style={{
              position: 'relative',
              top: '13px',
              left: '16px',
              padding: '12px',
            }}>
              <div className="kt-notification__item-title"> {`${perfil.Cliente}`}</div>
            </div>
            {menuPerfiles}

            <div className="clsProfileHeader">
              <Link to="/logout" className="userProfileButtonLogout link-btn-padding lnk-btn-left">
                <i className="dx-icon dx-icon-logout"></i>&nbsp;
                {intl.formatMessage({ id: "COMMON.LOGOUT" })}
              </Link>
              <Link to="/seguridad/usuarioLogin" onClick={() => reLoadPage()} className="userProfileButtonProfile classCerrarSesionProfile link-btn-padding lnk-btn-right" style={{ marginBottom: "10px" }}>
                <i className="dx-icon dx-icon-key"></i>&nbsp;
                {intl.formatMessage({ id: "COMMON.SECURITY" })}
              </Link>

            </div>

          </div>

        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = store => {
  return {
    user: store.auth.user,
    perfil: store.perfil.perfilActual,
    perfiles: store.perfil.perfiles,
    datosAutorizador: store.perfil.Autorizador
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPerfilActual: data => dispatch(perfilStore.actions.setPerfilActual(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserProfile));
