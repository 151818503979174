import React from "react";
import AcreditacionV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/AcreditacionV01";
import HomeIconV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/HomeIconV01";
import EmbarqueV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/EmbarqueV01";
import HospedajeIconV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/HospedajeIconV01";
import AsistenciaV01 from "../../partials/content/Acreditacion/Svg/DashboardMenu/AsistenciaV01";


class Constants extends React.Component {

  /*Url Api Principal: Módulos de Seguridad, Sistema, Administración, Identificación */
  static API_URL = "https://apimwlt.azure-api.net/administracion";//Desarrollo
  //static API_URL = "https://ase-2personneladministracion-backend-dev.azurewebsites.net";//Azure Static Web App
  //static API_URL = "https://api2personnelplus.whitelion.pe:8091"; //Produccion
  //static API_URL = "https://2pbackendwlt.azurewebsites.net"; //AZURE V1
  //static API_URL = "https://apps.whitelion.pe"; //AZURE V2

  /*Url Api Asistencia: Módulo de Asistencia */
  static API_URL_ASISTENCIA = "https://apimwlt.azure-api.net/asistencia";//Desarrollo
  //static API_URL_ASISTENCIA = "https://ase-2personnelasistenciaadm-backend-dev.azurewebsites.net";//Azure Static Web App


  static API_URL_LOCALHOST = "http://localhost:1010";//::::::-> NO COMENTAR, SE USA PARA EDIT/PRINT BADGE..:::::::
  static AppName = "2PERSONNEL";
  static AppVersion = "V.2.2";//Entrega 01-03-2024
  static SECONDS_TIMEOUT_FOR_LOCK = 400;
  static APLICACION = "AP00";
  static ID_TIPO_APLICACION = "WEB";
  static CAPTCHA_URL = "https://www.google.com/recaptcha/api.js"
  static CAPTCHA_SECRETKEYSITE = "6LeKHoIaAAAAAO4x8ArgbONUAXxENW4AZBTDr76q";
  static NAME_MODULO = "DESCRIPTION.WEB.MODULE.ADMINISTRATION";
  static CLIENTE_LOGOID = "LogoEmpresaPrincipal.png";
  static DESCRIPCION_COMPANIA_DEFAULT = "LAGUNAS NORTE" //Nombre del cliente para los reportes..
  static NOTIFY_BY = "SMTP"; //SMTP/SMS
  static FLAG_SPANISH = "188-peru.svg";//"131-chile.svg";  //Bandera por defecto asignado ->  

  static URL_REPORTING_SERVICES = "/RS/Rpt.aspx";
  static DASHBOARD = {
    ADMINISTRACION: {
      HEAD_COUNT: "https://app.powerbi.com/view?r=eyJrIjoiYjQ4NmViNjktM2JmZi00NDUyLWJhMTAtZWE1ZWQ4NzhkNGU0IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    },
    CAMPAMENTO: "https://app.powerbi.com/view?r=eyJrIjoiYTY1ZDk5MDAtNGRkZS00MGFkLTg3NzItYWNiY2IwMzlmYmQzIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    ACREDITACION: {
      INSITE: "https://app.powerbi.com/view?r=eyJrIjoiMTFiMDg3MTktNzU1Zi00OGVkLWFmZWEtNWU4MDkwNmY4OWJmIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      RECUENTO_ACREDITACION: "https://app.powerbi.com/view?r=eyJrIjoiNjlhYmVmZjktZDFmOC00MGFjLWJmZGEtOWJmMmRlMzMwYzc5IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      SEGUIMINETO: "https://app.powerbi.com/view?r=eyJrIjoiMTI1YjdkMGItZjAxMS00NGFkLWJlZDktMzQ3MTJjZDk0ODM4IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
    },
    ACCESO: {
      ACCESO_AREA_CRITICA: "https://app.powerbi.com/view?r=eyJrIjoiYTJkNThhYjktNmQ4ZC00YmRjLTljOGYtMzUzNTNiMzJhNjQ0IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      CONTROL_DE_PERSONAL: "https://app.powerbi.com/view?r=eyJrIjoiMjcwNzE5Y2ItZDgyNC00ZmY0LThlZGItMWQ5M2MyNGI5YmQxIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      TOP_INCUMPLIMIENTO_PERSONA: "https://app.powerbi.com/view?r=eyJrIjoiYzc0NDUxOTAtZmNhZS00MzAwLThkYmUtNmJkMDFlOWYwZDMxIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      RECUENTO_ENTRADAS_SALIDAS: "https://app.powerbi.com/view?r=eyJrIjoiODA5MGM3OTQtMTBhNS00MTc2LTk4YjMtYmIwY2MyYjY0NGY4IiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      HORAS_PICO_INGRESO_SALIDA: "https://app.powerbi.com/view?r=eyJrIjoiOTk1NDljZmEtOWU1Zi00MTk0LTk0MDYtZTgzZThlMGU1MThmIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9",
      REQUISITOS_POR_VENCER: "https://app.powerbi.com/view?r=eyJrIjoiNDYxYjM3YjEtMDlmYy00Njc4LTg2ZDktZTZmZjZjZjJmMzJmIiwidCI6IjczNWM4NzE3LWRlN2EtNDQyYS05NjkwLWJjNmNlOWEzMzQ3NSJ9"
    }
  };
  // static MENU_PRINCIPAL = [
  //   { classIcon: '2personnel', text: "CONFIG.SISTEMA", activo: 1, accion: null },
  //   { classIcon: 'acreditacion', text: 'ACCREDITATION.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8086/' },
  //   { classIcon: 'Transporte', text: 'TRANSPORT.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8085/' },
  //   { classIcon: 'hoteleria', text: 'HOSPITALITY.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8087/' },
  //   { classIcon: 'asistencia', text: 'ASSISTANCE.MAIN', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8093/' },

  // ];

  static MENU_PRINCIPAL = [
    { icon: <HomeIconV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: '2personnel', text: 'DESCRIPTION.WEB.MODULE.ADMINISTRATION', activo: 1, accion: null },
    { icon: <AcreditacionV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'acreditacion', text: 'DESCRIPTION.WEB.MODULE.ACREDITACION', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8086/' },
    { icon: <EmbarqueV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'Transporte', text: 'DESCRIPTION.WEB.MODULE.TRANSPORTE', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8085/' },
    { icon: <HospedajeIconV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'hoteleria', text: 'DESCRIPTION.WEB.MODULE.HOSPEDAJE', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8087/' },
    { icon: <AsistenciaV01 style={{ margin: "0 auto", textAlign: "center", display: "flow", }} />, classIcon: 'asistencia', text: 'DESCRIPTION.WEB.MODULE.ASISTENCIA', activo: 1, accion: 'https://2personnelplus.whitelion.pe:8093/' },
  ];

  static AUTENTICATE_AD = "S";//Agregar autenticacion AZURE AD
  static AZURE_AD_APP_ID = "28c77fa9-2a9e-4634-93b3-7133cb0193e2" //Produccion
  static AZURE_AD_SCOPE_DEFAULT = "api://9b78f4c9-dc4a-4a7f-b625-7c56cd475d88/access_as_user" //Produccion
  static AZURE_AD_AUTHORITY = "https://login.microsoftonline.com/735c8717-de7a-442a-9690-bc6ce9a33475"


  //static AZURE_AD_APP_ID = "34269c8c-3dc9-4a3d-a40c-4e414044e893" //Desarrollo
  //static AZURE_AD_SCOPE_DEFAULT = "api://a5496d8b-3df1-4767-8d0c-2197206fdea9/access_as_user" //Desarollo
}

export default Constants;
