import React, { Fragment, useState, useRef } from "react";
// import { Link, Switch, Route, Redirect } from "react-router-dom"; import {
// toAbsoluteUrl } from "../../../../_metronic";
import "./AuthPage.css";
// import ChangeForgottenPassword from
// "../../../pages/auth/ChangeForgottenPassword"; import queryString from
// 'query-string';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import { makeStyles } from '@material-ui/core/styles';

//import LanguageSelector from '../../layout/LanguageSelector';
import { injectIntl } from "react-intl";
// import { loadImages } from "../../../crud/auth.crud";
import { WithLoandingPanel } from "../../content/withLoandingPanel";
import LoginTopBar from "../../../pages/auth/LoginTopBar";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    paper: {
        marginRight: theme.spacing(2)
    }
}));

function AuthPageSplash(props) {
    const { intl, setEjecutarAnimacion, setViewLogin, handleAcreditacionChange, showImageGallery } = props;

    const objGaleria = useRef(null);
    const [imagesCarrusel,
        setImagesCarrusel] = useState([]);
    const personnelLogo = "/media/imgv1/2p_yb.png";

    const [anchorEl,
        setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <div className="content-2personnel-content">
                <LoginTopBar handleAcreditacionChange={handleAcreditacionChange} />
            </div>
        </Fragment>
    );
}

export default WithLoandingPanel(injectIntl(AuthPageSplash));
